import React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import BackgroundITCloud from "../components/background/BackgroundITCloud"
import ContactUs from "../components/ContactUs"

const Page = () => (
  <Layout>
    <PageHeader
      title="IT Cloud Benchmarking"
      subtitle="Monitor and improve your cloud's performance with us."
    />
        <section className="section-padding-top">
      <div className="container">
        <div className="row row-mobile-reverse">
          <div className="col-lg-6">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">
                IT Cloud
              </h2>
            </div>
            <p className="paragraph-3">
            VoerEir provides a comprehensive way to build, verify and deploy different IT cloud options and provide end user a secure, reliable, quick cloud option for private or public deployments. 
            </p>
            <p className="paragraph-3">
            VoerEir helps you to executes your build in different infrastructures, Openstack or Kubernetes. 
            </p>
            <p className="paragraph-3">
            We help our customers both using Touchstone to very cloud performance as well as with professional services to build cloud and migrate services
            </p>
          </div>
          <div className="col-lg-6 text-center">
          <div className="img-wrap box-bottom-left height-400 width-100">
              <BackgroundITCloud />
            </div>
          </div>
        </div>
      </div>
    </section>
    <ContactUs />
  </Layout>
)

export default Page
